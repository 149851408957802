export const teasers = [
  {
    category: 'IQOS ILUMA Onboarding',
    id: '1',
    index: 0,
    points: 40,
    color: 'bg-primary-turquoise',
    subtitle: 'Challenge abschließen und Gratis TEREA Packung sichern.*',
    title: 'IQOS Club Academy',
    url: 'https://de.iqos.com/academy',
    image: {
      alt: 'IQOS rewardshop',
      desktop: {
        x1: require('@/assets/shop/product-listing/iqos-academy.png'),
        x2: require('@/assets/shop/product-listing/iqos-academy@2x.png'),
        x3: require('@/assets/shop/product-listing/iqos-academy@3x.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/iqos-academy.png'),
        x2: require('@/assets/shop/product-listing/iqos-academy@2x.png'),
        x3: require('@/assets/shop/product-listing/iqos-academy@3x.png'),
      },
    },
    info: '* Nur solange der Vorrat reicht.',
  },
  {
    category: 'Entdecke die Welt um IQOS',
    id: '2',
    index: 1,
    points: '30 Statuspunkte',
    color: 'bg-secondary-amber',
    subtitle: 'Neue Stories entdecken und \n Statuspunkte sichern.',
    title: 'IQOS CLUB Stories',
    url: 'https://de.iqos.com/stories',
    image: {
      alt: 'IQOS stories',
      desktop: {
        x1: require('@/assets/shop/product-listing/img-stories.png'),
        x2: require('@/assets/shop/product-listing/img-stories@2x.png'),
        x3: require('@/assets/shop/product-listing/img-stories@3x.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/img-stories.png'),
        x2: require('@/assets/shop/product-listing/img-stories@2x.png'),
        x3: require('@/assets/shop/product-listing/img-stories@3x.png'),
      },
    },
  },
  {
    category: '',
    id: '3',
    index: 2,
    points: 'Bis zu 200 IQOS Points',
    color: 'bg-primary-turquoise',
    subtitle:
      'Empfehle IQOS Deinen Freunden<sup>1</sup> und sichere Dir jetzt pro Empfehlung bis zu 20 € in Form von 200 IQOS Points.<sup>2</sup>',
    title: 'Bis zu 200 IQOS Points für Deine Empfehlung.',
    url: 'https://www.iqos.com/de/de/iqos-club/dashboard/blog/mgm.html',
    image: {
      alt: 'Kunst genießt man am besten mit Freunden.',
      desktop: {
        x1: require('@/assets/shop/product-listing/header-rewardshop-art-box-slider-short.png'),
        x2: require('@/assets/shop/product-listing/header-rewardshop-art-box-slider-short.png'),
        x3: require('@/assets/shop/product-listing/header-rewardshop-art-box-slider-short.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/header-rewardshop-art-box-slider-long.png'),
        x2: require('@/assets/shop/product-listing/header-rewardshop-art-box-slider-long.png'),
        x3: require('@/assets/shop/product-listing/header-rewardshop-art-box-slider-long.png'),
      },
    },
    info: `
      Erwachsene Konsumenten, die für ihre Teilnahme an der Kampagne entlohnt wurden.
      <br/><br/>
      <sup>1</sup> Nur für erwachsene Raucher. Die Nutzung des Freundschaftscodes für kommerzielle Zwecke und eine Verbreitung auf eBay sind untersagt. Zudem ist die Nutzung des Freundschaftscodes auf 50 erfolgreiche Empfehlungen limitiert.
      <br/><br/>
      <sup>2</sup> Nach abgeschlossenem Kauf eines IQOS ILUMA PRIME mit Nutzung des Freundschaftscodes sowie erfolgreicher Registrierung Deiner Freunde erhältst Du 20 € in Form von 200 IQOS Points, die Du für Prämien wie Wunschgutscheine oder JET-Tank-Wertgutschein im IQOS CLUB eintauschen kannst. Nach abgeschlossenem Kauf eines IQOS ILUMA oder unseren Limited Editions mit Nutzung des Freundschaftscodes sowie erfolgreicher Registrierung Deiner Freunde erhältst Du 10 € in Form von 100 IQOS Points.
    `,
  },
];
