export default {
  toggles: [
    {
      name: 'maintenance',
      enabled: false,
    },
    {
      name: 'IQOS-1171', // TogetherX Main Toggle (related to all routes)
      enabled: true,
    },
    {
      name: 'IQOS-1394-documentation-phase',
      enabled: true,
    },
    {
      name: 'IQOS-1408-artist-rollouts',
      enabled: true,
    },
    {
      name: 'IQOS-1484-new-artist-rollouts-17-06-2024',
      enabled: true,
    },
    {
      name: 'IQOS-1504-10-years-news-article',
      enabled: true,
    },
    {
      name: 'IQOS-1503-levia-news-article',
      enabled: true,
    },
    {
      name: 'IQOS-1535-togx-tokyo-ugc-module',
      enabled: true,
    },
    {
      name: 'IQOS-1609-product-notes',
      enabled: true,
    },
    {
      name: 'IQOS-1586-raffle-popup',
      enabled: true,
    },
    {
      name: 'IQOS-1637-earning-overview-module',
      enabled: true,
    },
    {
      name: 'IQOS-1668-10-years-history',
      enabled: true,
    },
    {
      name: 'IQOS-1677-wave-2b-article',
      enabled: true,
    },
    {
      name: 'IQOS-1708-remove-community-wall',
      enabled: true,
    },
    {
      name: 'IQOS-1831-vday-2025',
      enabled: true,
    },
    {
      name: 'IQOS-1749-steve-aoki-landing',
      enabled: true,
      variant: {
        name: 'drop',
        enabled: true,
        payload: {
          type: 'number',
          value: '5',
        },
      },
    },
    {
      name: 'IQOS-1718-5-years',
      enabled: true,
      variant: {
        name: 'phase',
        enabled: true,
        payload: {
          type: 'number',
          value: '4',
        },
      },
    },
    {
      name: 'IQOS-1682-advent-2024',
      enabled: true,
      variant: {
        name: 'phase',
        enabled: true,
        payload: {
          type: 'string',
          value: 'landing',
          // value: 'closing',
          // value: 'countdown',
          // value: 'maintenance',
        },
      },
    },
    {
      name: 'IQOS-1682-advent-2024-testing',
      enabled: true,
    },
  ],
};
