import { CLUB_LOYALTY_URL } from '@/modules/auth/constants';

/**
 * @name FirstLevelMenuItems
 * @property {string}  id               - Unique string
 * @property {string}  handler          - If it has handler it must have a secondary menu prop (no href prop allowed)
 * @property {string}  href             - If it has href prop this item is a link (no handler props allowed)
 * @property {string}  text             - item label
 * @property {string}  subText          - item subtext
 * @property {string}  component        - Which component is used to display this item
 * @property {string}  desktopPosition  - Which position has this item in desktop header
 * @property {object}  secondLevel      - Sub menu (must be set if handler prop is present)
 */

/**
 * @name SecondLevelMenuItems
 * @desc the structure of each arrays is similar to {@link FirstLevelMenuItems}
 * @property {array}  firstList         - array of items that has to display in specific position
 * @property {array}  secondList        - array of items that has to display in specific position
 */

/**
 * @name ThirdLevelMenuItems
 * @desc the structure of each arrays is similar to {@link FirstLevelMenuItems}
 * @property {array}  firstList         - array of items that has to display in specific position
 * @property {array}  secondList        - array of items that has to display in specific position
 */
export const navItems = [
  {
    id: 'products',
    handler: 'products',
    text: 'Shop',
    subText: 'Teste & kaufe IQOS Tabakerhitzer',
    component: 'NavLink',
    desktopPosition: 'left',
    secondLevel: {
      firstList: [
        {
          id: 'products-devices',
          href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/tabakerhitzer-kaufen.html',
          text: 'IQOS Geräte',
          subText: 'Kaufe IQOS ILUMA & Limited Editions',
          component: 'NavLink',
          // thirdLevel: {
          //   firstList: [
          //     {
          //       id: 'products-devices-prime',
          //       href: 'https://www.iqos.com/de/de/shop/iluma-prime-kit-jade-green.html',
          //       text: 'IQOS ILUMA PRIME',
          //       subText: 'Stilvoll. Innovativ.',
          //       image:
          //         'IQOS_ILUMA_Navigation_Default_HandsConsumables_Prime.png',
          //       component: 'NavLink',
          //     },
          //     {
          //       id: 'products-devices-standard',
          //       href: 'https://www.iqos.com/de/de/shop/iluma-kit-pebble-grey.html',
          //       text: 'IQOS ILUMA',
          //       subText: 'Ikonisch. Fortschrittlich.',
          //       image:
          //         'IQOS_ILUMA_Navigation_Default_HandsConsumables_ILUMA.png',
          //       component: 'NavLink',
          //     },
          //     {
          //       id: 'products-devices-one',
          //       href: 'https://www.iqos.com/de/de/shop/iluma-one-kit-pebble-beige.html',
          //       text: 'IQOS ILUMA ONE',
          //       subText: 'Kompakt. All-in-One.',
          //       image:
          //         'IQOS_ILUMA_Navigation_Default_HandsConsumables_ILUMAONE.png',
          //       component: 'NavLink',
          //     },
          //   ],
          //   secondList: [
          //     {
          //       id: 'products-devices-buy',
          //       href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/tabakerhitzer-kaufen.html',
          //       text: 'Alle Geräte kaufen',
          //       component: 'NavLinkSmall',
          //     },
          //     {
          //       id: 'products-devices-compare',
          //       href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/modelle-vergleichen.html',
          //       text: 'Geräte vergleichen',
          //       component: 'NavLinkSmall',
          //     },
          //     {
          //       id: 'products-devices-discover',
          //       href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/iluma-entdecken.html',
          //       text: 'Was ist IQOS ILUMA?',
          //       component: 'NavLinkSmall',
          //     },
          //   ],
          //   footer: {
          //     id: 'products-devices-footer',
          //     image: 'sust-nav-banner-iqos-refreshed-1-840x480@2x.png',
          //     imageLink:
          //       'https://www.iqos.com/de/de/shop/iqos-refreshed-iluma-kit-moss-green.html',
          //     text: 'So gut wie neu, nur günstiger.',
          //     ctaLabel: 'Jetzt kaufen',
          //     component: 'NavLinkSmall',
          //     ctaLink:
          //       'https://www.iqos.com/de/de/shop/iqos-refreshed-iluma-kit-moss-green.html',
          //   },
          // },
        },
        {
          id: 'products-terea',
          href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/terea-tabaksticks-kaufen.html',
          text: 'TEREA Tabaksticks',
          subText: 'Bestelle Deine Lieblingssorten für IQOS ILUMA',
          component: 'NavLink',
        },
        {
          id: 'products-heets',
          href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/heets-kaufen.html',
          text: 'HEETS Tabaksticks',
          subText: 'Bestelle Deine Lieblingssorten für IQOS Originals',
          component: 'NavLink',
          // thirdLevel: {
          //   firstList: [
          //     {
          //       id: 'products-TEREA-1',
          //       href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/terea-tabaksticks-kaufen.html',
          //       text: 'TEREA Tabaksticks ',
          //       subText: 'für IQOS ILUMA',
          //       image: 'nav-terea.png',
          //       component: 'NavLink',
          //     },
          //     {
          //       id: 'products-heets-2',
          //       href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/heets-kaufen.html',
          //       text: 'HEETS Tabaksticks ',
          //       subText: 'für IQOS ORIGINALS',
          //       image: 'nav-heets.png',
          //       component: 'NavLink',
          //     },
          //   ],
          //   secondList: [],
          //   footer: {
          //     id: 'products-heets-footer',
          //     image: '10years-navigation-neon-840x480@2x.png',
          //     imageLink:
          //       'https://www.iqos.com/de/de/iluma-neon-purple-limited-edition.html',
          //     text: 'Es ist Zeit für einen Sommer voller Farben.',
          //     ctaLabel: 'Jetzt kaufen',
          //     component: 'NavLinkSmall',
          //     ctaLink:
          //       'https://www.iqos.com/de/de/iluma-neon-purple-limited-edition.html',
          //   },
          // },
        },
        {
          id: 'products-levia',
          href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/levia-nikotinsticks-kaufen.html',
          text: 'LEVIA Nikotinsticks',
          subText: 'Bestelle Deine Lieblingssorten für IQOS ILUMA',
          component: 'NavLink',
        },
        {
          id: 'products-accessories',
          href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/iqos-accessoires-kaufen.html',
          text: 'Zubehör und Accessoires',
          subText: 'Gestalte Dein IQOS Gerät nach Deinen Wünschen',
          component: 'NavLink',
          // thirdLevel: {
          //   firstList: [
          //     {
          //       id: 'products-accessories-iluma',
          //       href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/iluma-accessories-kaufen.html',
          //       text: 'IQOS ILUMA Zubehör',
          //       subText: 'Accessoires und Zubehör für IQOS ILUMA kaufen',
          //       component: 'NavLink',
          //     },
          //     {
          //       id: 'products-accessories-originals',
          //       href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/accessoires-kaufen.html',
          //       text: 'IQOS ORIGINALS Zubehör',
          //       subText: 'Accessoires und Zubehör für IQOS ORIGINALS kaufen',
          //       component: 'NavLink',
          //     },
          //   ],
          //   secondList: [],
          //   footer: {
          //     id: 'products-heets-footer',
          //     image: 'nav-onemio-stardrift-acc-840x480-de.png',
          //     imageLink:
          //       'https://www.iqos.com/de/de/tabakerhitzer-entdecken/iluma-limited-edition-kaufen.html',
          //     text: 'Entdecke neue Welten – mit unseren Stardrift Accessoires.',
          //     ctaLabel: 'Jetzt kaufen.',
          //     ctaLink:
          //       'https://www.iqos.com/de/de/tabakerhitzer-entdecken/iluma-limited-edition-kaufen.html',
          //   },
          // },
        },
      ],
      secondList: [
        {
          id: 'products-test',
          href: 'https://www.iqos.com/de/de/erst-testen.html',
          text: 'Kostenlos IQOS testen',
          component: 'NavLinkSmall',
        },
        {
          id: 'gerate-vergleichen',
          href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/modelle-vergleichen.html',
          text: 'Geräte vergleichen',
          component: 'NavLinkSmall',
        },
      ],
      footer: {
        id: 'products-footer',
        image: 'wave4-navi-downpricing-840x480_2x.png',
        imageLink:
          'http://www.iqos.com/de/de/tabakerhitzer-entdecken/tabakerhitzer-kaufen.html',
        text: 'Stell Dir vor, Dein neues Jahr wird rauchfrei.',
        ctaLabel: 'Erlebe echten Tabakgenuss. Mit IQOS ILUMA.',
        component: 'NavLinkSmall',
        ctaLink:
          'http://www.iqos.com/de/de/tabakerhitzer-entdecken/tabakerhitzer-kaufen.html',
      },
    },
  },
  {
    id: 'about',
    handler: 'about',
    text: 'Über IQOS',
    subText: 'Entdecke die Welt von IQOS',
    component: 'NavLink',
    desktopPosition: 'left',
    secondLevel: {
      firstList: [
        {
          id: 'about-discover',
          href: 'https://www.iqos.com/de/de/iqos-alternativen-zum-rauchen.html',
          text: 'Was ist IQOS?',
          subText: 'Entdecken die Welt von IQOS',
          component: 'NavLink',
        },
        {
          id: 'about-heets',
          href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/terea-sorten.html',
          text: 'Was sind TEREA Tabaksticks?',
          subText: 'Entdecke die neueste Tabakstick-Generation',
          component: 'NavLink',
        },
        {
          id: 'about-levia',
          href: 'https://www.iqos.com/de/de/tabakerhitzer-entdecken/levia-entdecken.html',
          text: 'Was sind LEVIA Nikotinsticks?',
          subText: 'Aromatisch-frischer Genuss ohne Tabak',
          component: 'NavLink',
        },
        {
          id: 'about-Verantwortung',
          href: 'https://www.iqos.com/de/de/verantwortung-fuer-die-umwelt.html',
          text: 'Verantwortung für die Umwelt',
          subText: 'Unsere Bemühungen für mehr Nachhaltigkeit',
          component: 'NavLink',
        },
        {
          id: 'about-News',
          href: 'https://www.iqos.com/de/de/news.html',
          text: 'News',
          subText: 'Immer auf dem neusten Stand mit unseren IQOS News',
          component: 'NavLink',
        },
      ],
      secondList: [],
      footer: {
        id: 'about-footer',
        image: 'wave2a-super-nav-technology-840x480x2.png',
        imageLink:
          'https://www.iqos.com/de/de/iluma-smartcore-induction-technologie.html',
        text: 'SMARTCORE INDUCTION SYSTEM™',
        ctaLabel: 'Die revolutionäre Technologie von IQOS ILUMA.',
        component: 'NavLinkSmall',
        ctaLink:
          'https://www.iqos.com/de/de/iluma-smartcore-induction-technologie.html',
      },
    },
  },
  {
    id: 'support',
    handler: 'support',
    text: 'Support & Services',
    subText: 'Erhalte Hilfe & Antworten',
    component: 'NavLink',
    desktopPosition: 'left',
    secondLevel: {
      firstList: [
        {
          id: 'support-help',
          href: 'https://www.iqos.com/de/de/support.html',
          text: 'Hilfe rund um IQOS',
          subText: 'IQOS Web-App, Garantie, Retoure & mehr',
          component: 'NavLink',
        },
        {
          id: 'support-steps',
          href: 'https://www.iqos.com/de/de/support/iluma-guide.html',
          text: 'Erste Schritte',
          subText: 'So nutzt Du Dein IQOS richtig',
          component: 'NavLink',
          /*
          thirdLevel: {
            firstList: [
              {
                id: 'products-accessories-duo',
                href:
                  'https://www.iqos.com/de/de/support/iqos-3-duo-guide.html',
                text: 'IQOS 3 DUO',
                subText: 'Erste Schritte IQOS 3 DUO.',
                component: 'NavLink',
              },
              {
                id: 'products-accessories-multi',
                href:
                  'https://www.iqos.com/de/de/support/iqos-3-multi-guide.html',
                text: 'IQOS 3 Multi',
                subText: 'Erste Schritte IQOS 3 Multi.',
                component: 'NavLink',
              },
              {
                id: 'products-accessories-plus',
                href:
                  'https://www.iqos.com/de/de/support/iqos-2-4-plus-guide.html',
                text: 'IQOS 2.4 Plus',
                subText: 'Erste Schritte IQOS 2.4 Plus.',
                component: 'NavLink',
              },
            ],
            secondList: []
          },
          */
        },
        {
          id: 'support-trouble',
          href: 'https://www.iqos.com/de/de/support/iluma-diagnose-tool.html',
          text: 'Online-Gerätediagnose',
          subText: 'Soforthilfe für Dein IQOS',
          component: 'NavLink',
          /*
          thirdLevel: {
            firstList: [
              {
                id: 'support-trouble-duo',
                href:
                  'https://www.iqos.com/de/de/support/diagnose-tool-iqos-3-duo.html',
                text: 'IQOS 3 DUO',
                subText: 'Problembehebung IQOS 3 DUO.',
                component: 'NavLink',
              },
              {
                id: 'support-trouble-multi',
                href:
                  'https://www.iqos.com/de/de/support/diagnose-tool-iqos-3-multi.html',
                text: 'IQOS 3 Multi',
                subText: 'Problembehebung IQOS 3 Multi.',
                component: 'NavLink',
              },
              {
                id: 'support-trouble-plus',
                href:
                  'https://www.iqos.com/de/de/support/diagnose-tool-iqos-24-plus.html',
                text: 'IQOS 2.4 Plus',
                subText: 'Problembehebung IQOS 2.4 Plus.',
                component: 'NavLink',
              },
            ],
            secondList: []
          },
          */
        },
      ],
      secondList: [
        {
          id: 'support-faq',
          href: 'https://www.iqos.com/de/de/support/faqs.html',
          text: 'FAQs',
          component: 'NavLinkSmall',
        },
        {
          id: 'support-contact',
          href: 'https://www.iqos.com/de/de/support/kontakt.html',
          text: 'Kontakt',
          component: 'NavLinkSmall',
        },
      ],
      footer: {
        id: 'support-devices-footer',
        image: 'wav4-navi-tabak-840x480_2x.png',
        imageLink:
          'https://www.iqos.com/de/de/tabakerhitzer-entdecken/terea-tabaksticks-kaufen.html',
        text: 'Für jeden Moment der passende Tabakgeschmack.',
        ctaLabel: 'Jetzt TEREA Tabakvielfalt entdecken.',
        component: 'NavLinkSmall',
        ctaLink:
          'https://www.iqos.com/de/de/tabakerhitzer-entdecken/terea-tabaksticks-kaufen.html',
      },
    },
  },
  {
    id: 'iqosclub',
    handler: 'iqosclub',
    text: 'IQOS CLUB',
    subText: 'Genieße exklusive Vorteile & Prämien',
    component: 'NavLink',
    desktopPosition: 'left',
    secondLevel: {
      firstList: [
        {
          id: 'iqosclub-club',
          href: 'https://www.iqos.com/de/de/loyalty.html',
          text: 'Dein IQOS CLUB',
          subText: 'Überblick über Deinen Status, Punkte & mehr',
          component: 'NavLink',
        },
        {
          id: 'iqosclub-earn',
          href: 'https://www.iqos.com/de/de/loyalty/punkte-sammeln.html',
          text: 'Punkte sammeln',
          subText: 'Verdiene Statuspunkte & IQOS Points',
          component: 'NavLink',
        },
        {
          id: 'iqosclub-redeem',
          href: 'https://www.iqos.com/de/de/loyalty/punkte-einloesen.html',
          text: 'Punkte einlösen',
          subText: 'Tausche IQOS Points gegen attraktive Prämien',
          component: 'NavLink',
        },
        {
          id: 'iqosclub-empfehlungsprogramm',
          href: 'https://www.iqos.com/de/de/freunde-empfehlen.html',
          text: 'Empfehlungsprogramm',
          subText: 'IQOS empfehlen & IQOS Points sammeln',
          component: 'NavLink',
        },
      ],
      secondList: [],
      footer: {
        id: 'support-devices-footer',
        image: 'wave3-navi-empfehlung-840x480_2x.png',
        imageLink: 'http://www.iqos.com/de/de/freunde-empfehlen.html',
        text: 'Empfehle IQOS und sichere Dir bis zu 200 IQOS Points im Wert von 20€. Hilf Freunden, rauchfrei ins neue Jahr zu starten.',
        ctaLabel: 'Jetzt empfehlen',
        component: 'NavLinkSmall',
        ctaLink: 'http://www.iqos.com/de/de/freunde-empfehlen.html',
      },
    },
  },
];
